import React from 'react';
import {
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel
} from '@material-ui/core';

import * as notes from '../lib/notes';

export interface NotePickerProps {
  readonly value: string;
  readonly onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => void;
}

export class NotePicker extends React.Component<NotePickerProps> {
  render() {
    return (
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend">Root Note</FormLabel>
        <RadioGroup
          aria-label="Root Note"
          name="root"
          value={this.props.value}
          onChange={this.props.onChange}
          row
        >
          {notes.allNotes.map((note) => {
            return (
              <FormControlLabel
                key={note.name}
                value={note.name}
                control={<Radio />}
                label={note.name}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  }
}
