import React from 'react';
import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  Checkbox,
  FormGroup,
  Tooltip
} from '@material-ui/core';
import { InfoOutlined as InfoIcon } from '@material-ui/icons';

import * as chordTypes from '../lib/chord_types';

export interface ChordPickerProps {
  readonly values: string[];
  readonly onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

export class ChordPicker extends React.Component<ChordPickerProps> {
  render() {
    return (
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend">Chord Types</FormLabel>
        <FormGroup row>
          {chordTypes.allChordTypes.map((type) => {
            const checked = this.props.values.indexOf(type.name) !== -1;

            return (
              <FormControlLabel
                key={type.name}
                control={
                  <Checkbox
                    checked={checked}
                    onChange={this.props.onChange}
                    name={type.name}
                  />
                }
                label={
                  <Box display="flex" alignItems="center">
                    <Typography>{type.name}</Typography>
                    {type.info && (
                      <Tooltip title={type.info}>
                        <Box
                          display="flex"
                          alignItems="center"
                          component="span"
                          ml={0.5}
                        >
                          <InfoIcon fontSize="small" color="action" />
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                }
              />
            );
          })}
        </FormGroup>
      </FormControl>
    );
  }
}
