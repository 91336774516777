import React from 'react';
import {
  Box,
  Typography,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  Tooltip
} from '@material-ui/core';
import { InfoOutlined as InfoIcon } from '@material-ui/icons';

import * as modes from '../lib/modes';

export interface ModePickerProps {
  readonly value: string;
  readonly onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => void;
}

export class ModePicker extends React.Component<ModePickerProps> {
  render() {
    return (
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend">Mode</FormLabel>
        <RadioGroup
          aria-label="mode"
          name="mode"
          value={this.props.value}
          onChange={this.props.onChange}
          row
        >
          {modes.allModes.map((mode) => {
            return (
              <FormControlLabel
                key={mode.name}
                value={mode.name}
                control={<Radio />}
                label={
                  <Box display="flex" alignItems="center">
                    <Typography>{mode.name}</Typography>
                    {mode.info && (
                      <Tooltip title={mode.info}>
                        <Box
                          display="flex"
                          alignItems="center"
                          component="span"
                          ml={0.5}
                        >
                          <InfoIcon fontSize="small" color="action" />
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                }
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  }
}
