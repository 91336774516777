import * as music from '@cuperman/music-theory';

export enum Notation {
  SHARP = 'SHARP',
  FLAT = 'FLAT'
}

export class Note {
  public readonly name: string;
  public readonly value: music.Note;

  constructor(name: string, value: music.Note) {
    this.name = name;
    this.value = value;
  }

  normalValue(): music.Note {
    return music.normalizeNote(this.value);
  }

  contextualName(notation: Notation): string {
    const [sharpName, flatName] = this.name.split('/', 2);

    if (notation === Notation.FLAT && flatName) {
      return flatName;
    } else {
      return sharpName;
    }
  }
}

export const a = new Note('A', music.Note.A);
export const b = new Note('B', music.Note.B);
export const c = new Note('C', music.Note.C);
export const d = new Note('D', music.Note.D);
export const e = new Note('E', music.Note.E);
export const f = new Note('F', music.Note.F);
export const g = new Note('G', music.Note.G);

export const cSharp = new Note('C#/Db', music.Note.C_SHARP);
export const dSharp = new Note('D#/Eb', music.Note.D_SHARP);
export const fSharp = new Note('F#/Gb', music.Note.F_SHARP);
export const gSharp = new Note('G#/Ab', music.Note.G_SHARP);
export const aSharp = new Note('A#/Bb', music.Note.A_SHARP);

export const dFlat = cSharp;
export const eFlat = dSharp;
export const gFlat = fSharp;
export const aFlat = gSharp;
export const bFlat = aSharp;

export const allNotes: Note[] = [
  c,
  cSharp,
  d,
  dSharp,
  e,
  f,
  fSharp,
  g,
  gSharp,
  a,
  aSharp,
  b
];

export function noteByValue(value: music.Note): Note {
  const note = allNotes.find((note) => {
    return music.normalizeNote(value) === note.normalValue();
  });

  if (typeof note === 'undefined') {
    throw new Error(`Unknown note with value "${value}"`);
  }

  return note;
}

export function noteByName(name: string): Note {
  switch (name) {
    case 'C':
      return c;
    case 'D':
      return d;
    case 'E':
      return e;
    case 'F':
      return f;
    case 'G':
      return g;
    case 'A':
      return a;
    case 'B':
      return b;
    case 'C#/Db':
    case 'C#':
    case 'Db':
      return cSharp;
    case 'D#/Eb':
    case 'D#':
    case 'Eb':
      return dSharp;
    case 'F#/Gb':
    case 'F#':
    case 'Gb':
      return fSharp;
    case 'G#/Ab':
    case 'G#':
    case 'Ab':
      return gSharp;
    case 'A#/Bb':
    case 'A#':
    case 'Bb':
      return aSharp;
    default:
      throw new Error(`Unknown note named "${name}"`);
  }
}
