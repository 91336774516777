import * as music from '@cuperman/music-theory';

import { Note, noteByValue } from './notes';

export interface ModeOptions {
  readonly info?: string;
}

export const DEFAULT_MODE_OPTIONS: ModeOptions = {};

export class Mode {
  public readonly name: string;
  private readonly scaleFn: (root: music.Note) => music.Mode;
  private readonly options: ModeOptions;

  constructor(
    name: string,
    scaleFn: (root: music.Note) => music.Mode,
    options?: ModeOptions
  ) {
    this.name = name;
    this.scaleFn = scaleFn;
    this.options = options || DEFAULT_MODE_OPTIONS;
  }

  get info(): string | undefined {
    return this.options.info;
  }

  notesInMode(root: Note): Note[] {
    return this.scaleFn(root.value).map((noteValue) => {
      return noteByValue(noteValue);
    });
  }
}

export const ionian = new Mode('Ionian', music.ionianMode, { info: 'Major' });
export const dorian = new Mode('Dorian', music.dorianMode);
export const phrygian = new Mode('Phrygian', music.phrygianMode);
export const lydian = new Mode('Lydian', music.lydianMode);
export const mixolydian = new Mode('Mixolydian', music.mixolydianMode);
export const aeolian = new Mode('Aeolian', music.aeolianMode, {
  info: 'Minor'
});
export const locrian = new Mode('Locrian', music.locrianMode);

export const allModes = [
  ionian,
  dorian,
  phrygian,
  lydian,
  mixolydian,
  aeolian,
  locrian
];

export function modeByName(name: string): Mode {
  const mode = allModes.find((mode) => mode.name === name);

  if (typeof mode === 'undefined') {
    throw new Error(`Unknown mode named "${name}"`);
  }

  return mode;
}
