import { Note, Notation } from './notes';
import { Mode } from './modes';

export class Scale {
  public readonly rootNote: Note;
  public readonly mode: Mode;

  constructor(rootNote: Note, mode: Mode) {
    this.rootNote = rootNote;
    this.mode = mode;
  }

  notes(): Note[] {
    return this.mode.notesInMode(this.rootNote);
  }

  notation(): Notation {
    const firsts = this.notes().map((note) =>
      note.contextualName(Notation.FLAT).substr(0, 1)
    );
    const uniques = Array.from(new Set(firsts));
    if (JSON.stringify(firsts) === JSON.stringify(uniques)) {
      return Notation.FLAT;
    } else {
      return Notation.SHARP;
    }
  }

  name(): string {
    const notation = this.notation();
    const rootName = this.rootNote.contextualName(notation);
    const modeName = this.mode.name;

    return [rootName, modeName].join(' ');
  }
}
