import React from 'react';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';

import * as scales from '../lib/scales';
import * as chordTypes from '../lib/chord_types';
import * as chords from '../lib/chords';

import { RomanNumeral } from './RomanNumeral';

export interface ChordsProps {
  readonly scale: scales.Scale;
  readonly chordTypes: chordTypes.ChordType[];
}

export class Chords extends React.Component<ChordsProps> {
  render() {
    const notation = this.props.scale.notation();

    return (
      <TableContainer>
        <Table>
          <TableBody>
            {this.props.scale.notes().map((note, index) => {
              return (
                <TableRow key={note.name}>
                  <TableCell>
                    <RomanNumeral decimalValue={index + 1} />
                  </TableCell>
                  <TableCell>{note.contextualName(notation)}</TableCell>
                  {this.props.chordTypes.map((chordType) => {
                    const chord = new chords.Chord(note, chordType);
                    if (chord.inScale(this.props.scale)) {
                      return (
                        <TableCell key={chord.name()}>
                          {note.contextualName(notation)} {chordType.name}
                        </TableCell>
                      );
                    } else {
                      return <TableCell key={chord.name()}>&nbsp;</TableCell>;
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
