import React from 'react';
import { Container, Typography, Box } from '@material-ui/core';

import * as notes from '../lib/notes';
import * as modes from '../lib/modes';
import * as chordTypes from '../lib/chord_types';

import { NotePicker, ModePicker, ChordPicker, Chords } from '.';
import { Scale } from '../lib/scales';

export interface AppProps {}

export interface AppState {
  readonly root: string;
  readonly mode: string;
  readonly chordTypes: { [name: string]: boolean };
}

export class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      root: notes.allNotes[0].name,
      mode: modes.allModes[0].name,
      chordTypes: chordTypes.allChordTypes.reduce((map, chordType) => {
        return { ...map, [chordType.name]: true };
      }, {})
    };

    this.changeNote = this.changeNote.bind(this);
    this.changeMode = this.changeMode.bind(this);
    this.changeChordTypes = this.changeChordTypes.bind(this);
  }

  changeNote(_event: React.ChangeEvent<HTMLInputElement>, value: string) {
    this.setState({
      root: value
    });
  }

  changeMode(_event: React.ChangeEvent<HTMLInputElement>, value: string) {
    this.setState({
      mode: value
    });
  }

  changeChordTypes(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    const input = event.target as HTMLInputElement;
    this.setState({
      chordTypes: {
        ...this.state.chordTypes,
        [input.name]: checked
      }
    });
  }

  render() {
    const scale = new Scale(
      notes.noteByName(this.state.root),
      modes.modeByName(this.state.mode)
    );
    const selectedChordTypes = Object.entries(this.state.chordTypes)
      .filter(([_name, value]) => value)
      .map(([name, _value]) => chordTypes.chordTypeByName(name));

    return (
      <Container maxWidth="lg">
        <Box component="header">
          <Typography component="h1" variant="h2">
            Jeffws Music
          </Typography>
        </Box>
        <Box component="section" mt={2}>
          <form>
            <NotePicker value={this.state.root} onChange={this.changeNote} />
            <ModePicker value={this.state.mode} onChange={this.changeMode} />
            <ChordPicker
              values={selectedChordTypes.map((type) => type.name)}
              onChange={this.changeChordTypes}
            />
          </form>
        </Box>
        <Box component="section" mt={2}>
          <Box component="header">
            <Typography component="h2" variant="h5">
              Chords in {scale.name()}
            </Typography>
          </Box>
          <Chords scale={scale} chordTypes={selectedChordTypes} />
        </Box>
      </Container>
    );
  }
}
