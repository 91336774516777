import React from 'react';
import { Typography } from '@material-ui/core';

export interface RomanNumeralProps {
  readonly decimalValue: number;
}

export class RomanNumeral extends React.Component<RomanNumeralProps> {
  romanNumeralValue(decimalValue: number): string {
    switch (decimalValue) {
      case 1:
        return 'I';
      case 2:
        return 'II';
      case 3:
        return 'III';
      case 4:
        return 'IV';
      case 5:
        return 'V';
      case 6:
        return 'VI';
      case 7:
        return 'VII';
      default:
        return decimalValue.toFixed(0);
    }
  }

  render() {
    return (
      <Typography>{this.romanNumeralValue(this.props.decimalValue)}</Typography>
    );
  }
}
