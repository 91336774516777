import * as music from '@cuperman/music-theory';

import { Note, noteByValue } from './notes';

export interface ChordTypeOptions {
  info?: string;
}

export const DEFAULT_CHORD_TYPE_OPTIONS = {};

export class ChordType {
  public readonly name: string;
  private readonly chordFn: (root: music.Note) => music.Chord;
  private readonly options: ChordTypeOptions;

  constructor(
    name: string,
    chordFn: (root: music.Note) => music.Chord,
    options?: ChordTypeOptions
  ) {
    this.name = name;
    this.chordFn = chordFn;
    this.options = options || DEFAULT_CHORD_TYPE_OPTIONS;
  }

  get info(): string | undefined {
    return this.options.info;
  }

  notesInChord(root: Note): Note[] {
    return this.chordFn(root.value).map((noteValue) => {
      return noteByValue(noteValue);
    });
  }
}

export const major = new ChordType('Major', music.majorChord, {
  info: 'Major 3rd'
});
export const minor = new ChordType('Minor', music.minorChord, {
  info: 'Minor 3rd'
});
export const diminished = new ChordType('Diminished', music.diminishedChord, {
  info: 'Minor 3rd, Diminished 5th'
});
export const major7th = new ChordType('Major 7th', music.major7thChord, {
  info: 'Major 3rd, Major 7th'
});
export const minor7th = new ChordType('Minor 7th', music.minor7thChord, {
  info: 'Minor 3rd, Minor 7th'
});
export const dominant7th = new ChordType(
  'Dominant 7th',
  music.dominant7thChord,
  {
    info: 'Major 3rd, Minor 7th'
  }
);
export const halfDiminished7th = new ChordType(
  'Half-diminished 7th',
  music.halfDiminished7thChord,
  {
    info: 'Minor 3rd, Diminished 5th, Minor 7th'
  }
);

export const allChordTypes: ChordType[] = [
  major,
  minor,
  diminished,
  major7th,
  minor7th,
  dominant7th,
  halfDiminished7th
];

export function chordTypeByName(name: string): ChordType {
  const chordType = allChordTypes.find((chordType) => chordType.name === name);

  if (typeof chordType === 'undefined') {
    throw new Error(`Unknown chord type named "${name}"`);
  }

  return chordType;
}
